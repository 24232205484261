<template>
  <v-img
    :src="require('../assets/CarTrust_new_logo.png')"
    class="my-3"
    contain
    height="250"
  />
</template>

<script>
export default {};
</script>

<style></style>
